const serverUrl = process.env.REACT_APP_SERVER_URL;
const wyreUrl = process.env.REACT_APP_WYRE_URL;
const eventUrl = process.env.REACT_APP_EVENT_URL;
const reCaptchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
const allowedRoles = process.env.REACT_APP_ALLOWED_ROLES;

const backendUrl = {
  authApp: process.env.REACT_APP_AUTH_SERVER,
  profileApp: process.env.REACT_APP_PROFILE_SERVER,
  walletApp: process.env.REACT_APP_WALLET_SERVER,
  currencyApp: process.env.REACT_APP_CURRENCY_SERVER,
  activityApp: process.env.REACT_APP_ACTIVITY_SERVER,
  dashboardApp: process.env.REACT_APP_DASHBOARD_SERVER,
  adminApp: process.env.REACT_APP_ADMIN_SERVER,
  transactionApp: process.env.REACT_APP_TRANSACTION_SERVER,
};

const serverCredentials = {
  accessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION,
};

export {
  serverUrl,
  backendUrl,
  wyreUrl,
  eventUrl,
  reCaptchaKey,
  allowedRoles,
  serverCredentials,
};
