import { backendUrl } from "../config";
import { HTTP_STATUS_CODE } from "../utils/Constants";

export const isValidResponse = (response) => {
  let responseData = response.data;
  return (
    responseData &&
    (responseData.code === HTTP_STATUS_CODE.OK ||
      responseData.code === HTTP_STATUS_CODE.CREATED ||
      response.status === HTTP_STATUS_CODE.OK)
  );
};

export const parseUrl = (url, dictionary) => {
  return url.replace(/\{(\w+)\}/g, (match, p1) => dictionary[p1]);
};

export const serverUrlMapping = (url) => {
  //TODO: Needd to update it for staging
  const mappings = [
    {
      patterns: ["/auth"],
      // baseUrl: "http://localhost:4000/dev/api" || backendUrl.authApp,
      baseUrl: backendUrl.authApp,
    },
    {
      patterns: ["/admins/settings", "/users/profile"],
      // baseUrl: "http://localhost:4001/dev/api" || backendUrl.profileApp,
      baseUrl: backendUrl.profileApp,
    },
    {
      patterns: ["/admins/wallets", "/users/wallets", "/users/recipients"],
      // baseUrl: "http://localhost:4002/dev/api" || backendUrl.walletApp,
      baseUrl: backendUrl.walletApp,
    },
    {
      patterns: ["/admins/currencies", "/admins/coins", "/users/currencies"],
      // baseUrl: "http://localhost:4003/dev/api" || backendUrl.currencyApp,
      baseUrl: backendUrl.currencyApp,
    },
    {
      patterns: ["/admins/activities", "/users/activities", "/users/userLogs"],
      // baseUrl: "http://localhost:4004/dev/api" || backendUrl.activityApp,
      baseUrl: backendUrl.activityApp,
    },
    {
      patterns: ["/admins/dashboard"],
      // baseUrl: "http://localhost:4005/dev/api" || backendUrl.dashboardApp,
      baseUrl: backendUrl.dashboardApp,
    },
    {
      patterns: [
        "/admins/transactions",
        "/admins/edd-verifications",
        "/admins/reports",
        "/users/transactions",
      ],
      // baseUrl: "http://localhost:4007/dev/api" || backendUrl.transactionApp,
      baseUrl: backendUrl.transactionApp,
    },
    {
      patterns: ["/admins", "/admins/users"],
      // baseUrl: "http://localhost:4006/dev/api" || backendUrl.adminApp,
      baseUrl: backendUrl.adminApp,
    },
  ];

  for (const { patterns, baseUrl } of mappings) {
    if (patterns.some((pattern) => url.startsWith(pattern))) {
      return baseUrl;
    }
  }

  return null;
};
